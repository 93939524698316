.row{
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 200px;

}
.codeSquare{
    width: 140px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(112, 112, 112);
    font-size: 124px;
    font-weight: bold;
    color: #fff;
}