.popup__layout_bottom{
	z-index: 3;
    max-width: 500px;
	background: white;
	padding: 25px;
}
.popup__layout_right{
	z-index: 3;
	background: white;
	max-width: 320px;
    padding: 25px;
}
.header_container{
    display: flex;
    justify-content: space-between;
}
.header_title{
    font-size: 20px;
    color: #0060B7;
}
.login__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
}

.message{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0 30px;
}
.btn_return{
    padding: 10px 50px;
    border-radius: 16px;
    border: 1px solid #0060B7;
    font-weight: 600;
    color: #0060B7;
    margin-bottom: 30px;
}

.test{
    position: absolute;
    bottom: 0;
    height: 75vh;
    z-index: 3;
	background: white;
}