.s_layout{
    min-height: 100%;
}
.s_layout.blur{
    filter: blur(5px);
    -webkit-filter: blur(5px);
    transition: filter 0.3s ease;
}

.tribe_unknown{
    background-color: #eee;
}
.tribe_air{
    background-color: #eee;
}
.tribe_earth{
    background-color: #eee;
}
.tribe_fire{
    background-color: #7d0000;
}
.tribe_water{
    background-color: #116;
}