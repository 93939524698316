.btn{
    color: #fff;
    border: 1px solid #fff;
    padding: 15px 25px;
    margin: 5px;
}
.column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.input{
    outline: none;
    padding: 10px 15px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #eee;
    border-radius: 30px;
    width: 80%;
    box-sizing: border-box;
    font-size: 16px;
}
.form_btn{
    background-color: #eee;
    color: #000;
    padding: 15px 25px;
    cursor: pointer;
    width: 50%;
}