.card__wrapper{
    width: 50%;
    margin-top: 50px;
}
.card__content{
    padding: 30px;
    text-align: center;
}
.text_main{
    color: var(--TextDefault, #0060B7);
    font-size: 48px;
    font-weight: 600;
    margin: 32px 0 8px;
}
.text_sub{
    color: #1F85C5;
    font-size: 16px;
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
}
.btn_container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}