.section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
    padding-top: 80px;
}
.left{
    width: 20%;
}
.middle{
    width: 38%;
    min-width: 588px;
}
.right{
    width: 38%;
}

.wrapper{
    border: 1px solid #2b2d36;
    border-radius: 24px;
    padding: 24px 16px;
    box-sizing: border-box;
    background: #1d202a;
    box-sizing: border-box;
}

/* MIDDLE */
.subject_header{
    border-radius: 24px;
    padding: 24px 0 12px 28px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    /* color: var(--belyy); */
    color: #fff;
    min-width: 540px;
    width: 100%;
    position: relative;
    background: #4180df;
    overflow: hidden;
    margin-bottom: 16px;
}
.subject_header::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 280px;
    background-image: url('../assets/math.svg');
    background-repeat: no-repeat;
    z-index: 1;
}
.subject_header::after{
    position: absolute;
    content: "";
    backdrop-filter: blur(6px);
    background: linear-gradient(0deg, #043f96 0%, rgba(4, 63, 150, 0) 100%);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.content{
    position: relative;
    z-index: 2;
}
.subject_title{
    font-family: "Open Sans", sans-serif;
    margin-bottom: 16px;
}
.subject_stats{
    display: flex;
}
.stats_block{
    border: 1px solid #fff;
    border-radius: 16px;
    padding: 8px 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 4px 4px 11px 0 rgba(255, 255, 255, 0.25);

    margin-right: 16px;
}
.stats_block svg{
    margin-right: 8px;
}

/* CHAPTER */
.chapter{
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    background: #2a2d36;
    display: flex;
    cursor: pointer;
    margin-block: 12px;
}
.lesson{
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}