.library{
    padding-top: 80px;
}
.wrapper{
    border: 1px solid #2b2d36;
    border-radius: 24px;
    padding: 24px 16px;
    box-sizing: border-box;
    background: #1d202a;
    box-sizing: border-box;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 24px;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin-bottom: 24px;
}
.row{
    display: flex;
}
.card{
    background: #2a2d36;
    border-radius: 20px;
    padding: 0px 0px 16px 0px;
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card_header{
    padding: 0px 16px;
    box-sizing: border-box;
    margin: 16px 0;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}
.btn{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 13px;
    color: #fff;
    background: #474748;
    padding: 8px 24px;
    border-radius: 12px;
}