.login_block{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(45deg,#F17C58, #E94584, #24AADB , #27DBB1,#FFDC18, #FF3706);
    background-size: 600% 100%;
    animation: gradient 16s linear infinite;
    animation-direction: alternate;

    display: flex;
    align-items: start;
    justify-content: center;
}
@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}
.wrapper{
    padding: 28px 24px;
    backdrop-filter: blur(100px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    backdrop-filter: blur(100px);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);

    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

.formLogin{
    border: 1px solid #2b2d36;
    border-radius: 32px;
    padding: 56px 0px;
    background: #1d202a;
    width: 100%;
    height: 100%;
    color: #fff;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.title{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 32px;
    color: #fff;
}
.form_loginFieldsBlock{
    padding: 32px 0;
}
.form_loginFields_row{
    margin-bottom: 16px;
}
.input_label{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 12px;
    color: #5f6268;
}
.form_inputField{
    border: 1px solid #353740;
    border-radius: 16px;
    padding: 16px 24px;
    background: #2a2d36;
    color: rgba(255, 255, 255, 0.29);
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
}

.btn_login{
    border-radius: 16px;
    padding: 20px 48px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: #009797;
}