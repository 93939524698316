.btn_container{
    text-align: center;
}
.btn_solid_blue{
    border-radius: 16px;
    background: #0060B7;
    color: #fff;
    padding: 10px 50px;
    font-size: 16px;
    font-weight: 600;
}
.btn_solid_green{
    box-shadow: 0 2px 10px 0 var(--bg-pop-up-darknes);
    background: #428f8a;
    border-radius: 16px;
    padding: 16px 56px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: var(--bg-secondary);
}
.btn_outline_green{
    border: 2px solid #428f8a;
    border-radius: 16px;
    padding: 12px 24px;
    font-weight: 700;
    font-size: 16px;
    color: #428f8a;
    margin-right: 24px;
}