.section{
    display: flex;
    justify-content: space-between;

    width: 100%;
    background: #1d202a;
    border-radius: 24px;
    padding: 80px 20px 0;
    box-sizing: border-box;
}
.problem_desc{
    font-family: var(--font5);
    font-weight: 400;
    font-size: 16px;
    color: #8e8e8e;
    text-align: center;
}
.problem_text{
    display: flex!important;
    justify-content: center;
    align-items: center;

    font-family: var(--font5);
    font-weight: 500;
    font-size: 32px;
    color: #fff;

    margin-bottom: 48px;
}
.problem_field{
    border: 1px solid #656771;
    border-radius: 16px;
    padding: 16px 24px;
    width: 200px;
    height: 65px;
    outline: none;
    background-color: transparent;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: rgba(255, 255, 255, 0.888);

    margin: 0 auto;
}
.btn_check{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 25px auto;

    border-radius: 12px;
    padding: 14px 32px;
    background: #474748;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    color: #a9a9a9;
}

 /* LEFT */
.left{
    width: 33%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.correct_label{
    padding: 32px 0 16px 0;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #8e8e8e;
}
.record_block{
    border-radius: 8px;
    padding: 4px 4px 4px 16px;

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    color: #8e8e8e;
    
    width: 50%;
    display: flex!important;
    justify-content: space-between;
    margin-bottom: 8px;
    height: 35px;
    box-sizing: border-box;
}

.record_block.correct{
    border: 1px solid rgba(33, 125, 39, 0.25);
    background: rgba(33, 125, 39, 0.1);
}
.correct_redo{
    border: 1px solid #6c1814;
    background: rgba(33, 125, 39, 0.1);
}
.record_block.wrong{
    border: 1px solid #6c1814;
    background: rgba(206, 54, 49, 0.25);
}
.record_block.default{
    border: 1px solid #7a7a7a;
    background: rgba(144, 144, 144, 0.25);
}

.record_problem{
    width: 60%;
}
.record_timeSpent{
    border-radius: 7px;
    padding: 0px 8px;
    
    width: 20%;
    text-align: center;
}

.correct .record_timeSpent,
.correct_redo .record_timeSpent{
    background: rgba(33, 125, 39, 0.25);
}
.wrong .record_timeSpent{
    background: rgba(207, 15, 8, 0.25);
}
 /* MIDDLE */

.middle{
    border: 1px solid #2b2d36;
    border-radius: 24px;
    padding: 24px 16px;
    width: 33%;
    height: 461px;
    background: #2a2d36;

    display: flex;
    flex-direction: column;
}
.stats{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
}
.remainingQuestions{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #fff;
}
.timer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
}
.timer_label{
    font-weight: 300;
    font-size: 19px;
    letter-spacing: 0.01em;
    color: #ffd702;
}
.label_yellow{
    color: #ffd702;
}
.label_red{
    color: #ff0000;
}
/* RIGHT */
.right{
    width: 33%;
    display: flex;
}
.numberOfTries{
    width: 20%;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}
.correct .numberOfTries{
    color: #185c1c;
}
.wrong .numberOfTries{
    color: #af1610;
}