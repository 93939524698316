.nav{
    display: flex;
    justify-content: space-between;
    /* background-color: #888; */
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 8px 5%;
    height: 64px;
    box-sizing: border-box;
}
.left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25%;
}
.middle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
}
.navBtn{
    padding: 0;
}

.navItem_wrapper{
    border-radius: 16px;
    padding: 8px 12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.05) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.navItem_wrapper img{
    width: 24px;
    height: 24px;
}

/* middle */
.middle .navItem_wrapper{
    padding: 8px 16px;
}
.middle_left button:first-child{
    margin-right: 16px;
}
.navItem_wrapper .tribeIcon{
    width: 44px;
    height: 44px;
}
.middle_middle.navItem_wrapper{
    margin: 0 32px;
    padding: 4px;
}
.middle_right button:first-child{
    margin-right: 16px;
}
/* right */
.full_name{
    font-family: var(--second-family);
    font-weight: 800;
    font-size: 16px;
    color: #fff;
    padding: 0 8px;
}
.burger{
    display: none;
}

@media only screen and (max-width: 768px){
    .full_name{
        display: none;
    }
    .burger{
        display: flex;
    }
    
}
@media only screen and (max-width: 550px){
    .middle_middle.navItem_wrapper{
        margin: 0 16px;
    }
}
@media only screen and (max-width: 550px){
    .nav{
        padding: 8px 0;
    }
    .middle_middle.navItem_wrapper{
        margin: 0 8px;
    }
    .left .navItem_wrapper{
        border-radius: 0 16px 16px 0;
    }
    .right .navItem_wrapper{
        border-radius: 16px 0 0 16px;
    }
}