.section{
    padding: 80px 16px 0;
    /* background-color: #fff; */
    min-height: 100vh;
    box-sizing: border-box;
}
.list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 16px 0;
}
.student_card{
    border-radius: 20px;
    padding: 8px 16px;
    background-color: #fff;
}

.exercise {
    position: absolute;
    width: 100%;
    height: 100vh;
}

.exercise_background {
    width: 100%;
    height: 100%;
    background-color: #116;
}

.exercise_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 24px;
}


.lesson_block{
    margin-bottom: 28px;
}
.lesson_name{
    margin-bottom: 4px;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}
.block{
    border-radius: 20px;
    padding: 24px 8px;
    background: rgba(255, 255, 255, 0.25);
}
.block_title{
    font-family: var(--second-family);
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.block_title img{
    margin-right: 10px;
    padding-left: 10px;
}


.header{
    display: flex;
    justify-content: space-between;
}
.header_text{
    width: 48%;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #fff;
    margin: 0 0 16px 16px;
}


.word_block{
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
}
.word{
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.75);
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    color: #3f3f3f;
    border-radius: 12px;
    height: 40px;
    width: 100%;
}
.word_english,
.word_russian{
    display: flex;
    width: 48%;
}
.word_level{
    width: 40px;
    height: 100%;
    border-right: 1px solid rgba(204, 204, 204, 0.1);
    border-radius: 12px 0 0 12px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.word_text{
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    padding-left: 16px;
}